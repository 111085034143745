<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="steps-wrap">
      <b-container>
        <b-row>
          <b-col>
            <el-steps :active="active" finish-status="success">
              <el-step :title="$t('vipPaymentMethodConfirmPayment')"></el-step>
              <el-step :title="$t('vipPaymentMethodSubmittedSuccessfully')"></el-step>
              <el-step :title="$t('vipPaymentMethodPaymentSuccessful')"></el-step>
            </el-steps>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="con-wrap">
      <b-container>
        <b-row>
          <b-col>
            <div class="set-meal-wrap">
              <img src="@/../public/icon/pay/vipPaymentMethod/d11.jpg" alt="">
              <div class="tip-txt">
                <p>{{$t('vipPaymentMethodTipA')}}<span>{{vipName}}</span>{{$t('vipPaymentMethodTipB')}}</p>
                <p>{{$t('vipPaymentMethodTipC')}}<span>{{$t('vipPaymentMethodCurrency')}}{{vipPrice}}</span>{{$t('vipPaymentMethodTipD')}}</p>
              </div>
            </div>
            <div class="select-option-wrap">
              <!-- <p class="atit">{{$t('vipPaymentMethodSelect')}}</p>
              <div class="option-wrap">
                <div :class="index === PaymentMethodActive ? 'option-btn active' : 'option-btn'" v-for="(item,index) in paymentIconArr" :key="index" @click="selectPaymentMethod(item, index)">
                  <img :src="item.icon" alt="">
                </div>
              </div> -->
              <div class="pay-wrap">
                <div class="pay-btn" @click="newPayment">{{$t('vipPaymentMethodImmediatePayment')}}</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <footer-nav></footer-nav>
    <form class="payment" :action="paymentParams.url" method="post" ref="form">
      <input class="a" type="text" name="charset" id="charset" :value="paymentParams.charset"/>
      <input class="a" type="text" name="data" id="data" :value="paymentParams.data"/>
      <input class="a" type="text" name="signType" id="signType" :value="paymentParams.signType"/>
      <input class="a" type="text" name="sign" id="sign" :value="paymentParams.sign"/>
      <input class="a" type="text" name="extend" id="extend" :value="paymentParams.extend"/>
    </form>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      footerNav
    },
    data (){
      return {
        active: 1,
        PaymentMethodActive: 0,
        // 7支付宝 6微信 3线下
        PaymentMethod: 6,
        paymentIconArr: [
          {
            icon: require("@/../public/icon/pay/vipPaymentMethod/wx.jpg"),
            paymentType: 6
          },
          {
            icon: require("@/../public/icon/pay/vipPaymentMethod/zfb.jpg"),
            paymentType: 7
          },
          {
            icon: require("@/../public/icon/pay/vipPaymentMethod/xx.jpg"),
            paymentType: 3
          }
        ],
        vipId: null,
        vipPrice: null,
        vipName: null,
        token: null,
        paymentParams: {
          extend: "",
          charset: "",
          data: "",
          sign: "",
          signType: "",
          url: ""
        }
      }
    },
    created() {
      this.vipId = this.$route.query.vipId;
      this.vipPrice = this.$route.query.vipPrice;
      this.vipName = this.$route.query.vipName;
      this.getMemberCardPay();
    },
    methods: {
      selectPaymentMethod(item, index){
        this.PaymentMethodActive = index;
        this.PaymentMethod = item.paymentType;
      },
      // 立即支付
      payment(){
        if(this.PaymentMethod === 3){
          const params = {
            type: 1,
            link: "/pay/vipPay",
            params: {
              payType: this.PaymentMethod
            }
          }
          dump.link(params);
          return false;
        }
        this.$api.vipPayment({
          token: localStorage.getItem("token"),
          vipId: this.vipId,
          paymentType: this.PaymentMethod
        }).then(res => {
          if(res.status === 100){
            const params = {
              type: 1,
              link: "/pay/vipPay",
              params: {
                appId: res.data.appid,
                payType: this.PaymentMethod,
                qrCode: res.data.qrCode,
                orderCode: res.data.order_no,
                vipId: this.vipId
              }
            }
            dump.link(params);
          }
        }).catch(err => console.error(err))
      },
      getMemberCardPay(){
        this.$api.memberCardPay({
          vipId: this.vipId,
          token: this.token,
          // 7 杉德支付
          paymentType: "7"
        }).then(res => {
          if(res.status == 100){
            this.paymentParams.extend = res.data.extend;
            this.paymentParams.charset = res.data.charset;
            this.paymentParams.data = res.data.data;
            this.paymentParams.sign = res.data.sign;
            this.paymentParams.signType = res.data.signType;
            this.paymentParams.url = res.data.url;
          }
        }).catch(err => console.error(err))
      },
      // 调用杉德支付 
      newPayment(){
        this.$refs.form.submit();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/view/pay/vipPaymentMethod/vipPaymentMethod";
</style>
